@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input.hide-arrows[type="number"]::-webkit-outer-spin-button,
  input.hide-arrows[type="number"]::-webkit-inner-spin-button,
  input.hide-arrows[type="number"] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield;
  }
}

@layer utilities {
  .hexagon {
    position: relative;
    height: 320px;
    width: 341px;
    background-color: theme("colors.theme.olive");
    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);

    @media only screen and (min-width: 640px) {
      height: 365px;
      width: 435px;
    }
  }
}

body {
  margin: 0;
  font-family: "OpenDyslexic", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "OpenDyslexic";
  src: url("./fonts/OpenDyslexic-Regular.woff2") format("woff2"),
    url("./fonts/OpenDyslexic-Regular.woff") format("woff");
}
